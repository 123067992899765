import React, { Dispatch, FC, SetStateAction } from 'react';
import s from './ModulesMenu.module.scss'
import arrow from '../../../assets/images/arrow-up-2.png'
import { NavLink } from 'react-router-dom';

interface ModulesMenuProps {
    setIsClosed?: Dispatch<SetStateAction<boolean>>
}

const ModulesMenu: FC<ModulesMenuProps> = ({ setIsClosed }) => {

    const handleClick = () => {
        setIsClosed && setIsClosed(false)
    }

    return (
        <div className={s.dropdown}>
            <button className={s.btn}>
                Модули
                <img src={arrow} alt="arrow" />
            </button>
            <div className={s.dropdown_menu}>
                <NavLink
                    onClick={handleClick}
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={'module-one'}>Модуль 1</NavLink>
                <NavLink
                    onClick={handleClick}
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={'module-two'}>Модуль 2</NavLink>
                <NavLink
                    onClick={handleClick}
                    className={({ isActive }) => isActive ? `${s.dropdown_active} ${s.menu_link}` : s.menu_link}
                    to={'module-three'}>Модуль 3</NavLink>
            </div>
        </div>
    );
};

export default ModulesMenu;