import React, { FC, useState } from 'react';
import s from './ExpectedResult.module.scss'
import checkMark from '../../assets/images/mark.svg'
import checkMark2 from '../../assets/images/checkmark2.svg'
import checkMark3 from '../../assets/images/checkmark3.svg'
import { IModule } from '../../redux/types';
import { backUrl } from '../../utils';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface ExpectedResultProps {
    result_bg: IModule['result_bg']
    results: IModule['results']
    moduleNum: string
}

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const ExpectedResult: FC<ExpectedResultProps> = ({ result_bg, results, moduleNum }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <section>
            <h2 className={s.title}>Результат, который вы получите</h2>
            <motion.div
                ref={ref}
                initial={false}
                animate={
                    isLoaded && isInView && inView
                        ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 0.6, delay: 0.6 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsInView(true)}
                className={s.card}>
                <ul className={s.left_block}>
                    {
                        results.map((el, i) => (
                            <motion.li
                                initial={{ opacity: 0, x: -50 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.6, delay: 0.8 + +`0.${i}` }}
                                key={el.id} className={s.info}>
                                <img
                                    src={moduleNum === '1' ? checkMark : moduleNum === '2' ? checkMark2 : checkMark3}
                                    alt="check_mark" />
                                <p><span data-module={moduleNum}> {el.title.split(' ')[0]}</span> {el.title.split(' ').slice(1).join(' ')}</p>
                            </motion.li>
                        ))
                    }

                </ul>
                <img
                    onLoad={() => setIsLoaded(true)}
                    className={s.resultImg} src={result_bg ? backUrl + result_bg : ''} alt="cardImg" />
            </motion.div>
        </section>
    );
};

export default ExpectedResult;