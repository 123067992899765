import React, { FC } from 'react';
import s from './Study.module.scss'
import { useAppSelector } from '../../../../redux/hooks';
import { Link } from 'react-router-dom';
import ShowFeedback from '../../../../Components/ShowFeedback/ShowFeedback';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'

const Study: FC = () => {
    const { info } = useAppSelector(state => state.info)

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <>
            {
                info && info.study_title &&
                <motion.section
                    ref={ref}
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.7, delay: 0.3 }}
                    className='container'>
                    <div className={s.wrapper}>
                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8, delay: 0.4 }}
                            className={s.info}>
                            <div className={s.top_title_border}>
                                <h4 className={s.top_title}>Образовательня программа</h4>
                            </div>
                            <h2 className={s.title}>{info.study_title}</h2>
                            <h3 className={s.subtitle}>Осознанные Торговые Стратегии Ваш <span>путь к успеху!</span></h3>
                            <p className={s.description}>{info.study_description}</p>
                        </motion.div>
                        <div className={s.btns_wrapper}>
                            <ShowFeedback>Записаться на курс</ShowFeedback>
                            <Link className={s.link} to={'/program'}>Подробнее</Link>
                        </div>
                    </div>
                </motion.section>
            }
        </>
    );
};

export default Study;