import React from 'react';
import './App.scss'
import './i18n'
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';

const App = () => {
  // const { t, i18n } = useTranslation()




  return (
    <div style={{
      // filter: isBlurred ? 'blur(10px) brightness(10%)' : 'none',
      // transition: 'filter 0.3s ease',
      // backgroundColor: isBlurred ? 'black' : 'transparent',
      // minHeight: '100vh'
    }}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default App;