import React, { FC } from 'react';
import s from './ArrowRight.module.scss';

interface ArrowRightProps {
    strokeColor: string;
    fillColor: string;
}

const ArrowRight: FC<ArrowRightProps> = ({ strokeColor, fillColor }) => {
    const markerId = `marker-${Math.random().toString(36).substr(2, 9)}`;

    return (
        <>
            <svg
                className={s.arrow_right}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 50"
                width="100"
                height="50"
            >
                <g
                    strokeWidth="5"
                    stroke={strokeColor}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="10" y1="25" x2="70" y2="25" markerEnd={`url(#${markerId})`} />
                </g>
                <defs>
                    <marker
                        id={markerId}
                        markerWidth="3"
                        markerHeight="10"
                        refX="5"
                        refY="5"
                        orient="auto"
                        viewBox="0 0 10 10"
                    >
                        <polygon
                            points="0,10 0,0 10,5"
                            fill={fillColor}
                        />
                    </marker>
                </defs>
            </svg>
        </>
    );
};

export default ArrowRight;
