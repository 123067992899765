import React, { FC, useState, useEffect } from 'react';
import s from './WhyCard.module.scss';
import { IWhyWe } from '../../../../../redux/types';
import { AnimatePresence, motion } from 'framer-motion';
import close from '../../../../../assets/images/ic_round-close.svg';
import { backUrl } from '../../../../../utils';

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

const WhyCard: FC<IWhyWe> = ({ description, logo, title, id, bg_fone }) => {
    const [selectedId, setSelectedId] = useState<null | string>(null);

    useEffect(() => {
        if (selectedId) {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = (e) => {
                setSelectedId(null);
            };
            document.addEventListener('keydown', (e) => {
                if (e.code === 'Escape') {
                    setSelectedId(null);
                }
            });
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        return () => {
            window.onpopstate = () => { };
            document.removeEventListener('keydown', () => { });
        };
    }, [selectedId, setSelectedId]);

    return (
        <>
            <motion.div
                variants={item}
                layoutId={`${id}`}
                onClick={() => setSelectedId(`${id}`)}
                className={s.card}
            >
                <img src={backUrl + logo} alt={`${title} giff`} />
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    className={s.info_wrapper}>
                    <h4 className={s.title}>{title}</h4>
                    <p className={s.desc}>{description.length > 160 ? description.slice(0, 160) + '...' : description}</p>
                </motion.div>
            </motion.div>

            <AnimatePresence>
                {selectedId && (
                    <motion.div
                        onClick={() => setSelectedId(null)}
                        layoutId={selectedId}
                        className={s.modal}
                    >
                        <motion.div
                            initial={{ opacity: 0, y: 700 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            onClick={(e) => e.stopPropagation()}
                            className={s.container}
                            style={{ backgroundImage: `url(${backUrl + bg_fone})` }}
                        >
                            <motion.div
                                initial={{ opacity: 0, scale: 0, x: 100 }}
                                animate={{ opacity: 1, scale: 1, x: 0 }}
                                transition={{ duration: 0.8 }}
                                className={s.info}
                            >
                                <motion.h2
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.8, delay: 0.3 }}
                                    className={s.title}
                                >
                                    {title}
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.8, delay: 0.5 }}
                                    className={s.desc}
                                >
                                    {description}
                                </motion.p>
                            </motion.div>
                            <motion.button className={s.close} onClick={() => setSelectedId(null)}>
                                <img src={close} alt="close icon" />
                            </motion.button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default WhyCard;
