import { configureStore } from '@reduxjs/toolkit';
import homeSlice from './slices/homeSlice';
import infoSlice from './slices/infoSlice';
import modulesSlice from './slices/modulesSlice';
import programSlice from './slices/programSlice';


const store = configureStore({
    reducer: {
        home: homeSlice,
        info: infoSlice,
        mmodules: modulesSlice,
        program: programSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;