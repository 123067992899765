import React, { FC } from 'react';
import s from './CourseOverview.module.scss'
import { IModule } from '../../redux/types';
import { backUrl } from '../../utils';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface CourseOverviewProps {
    learnings: IModule['learnings']
    moduleNum: string
}

const CourseOverview: FC<CourseOverviewProps> = ({ learnings, moduleNum }) => {

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <>
            {
                learnings.length > 0 &&
                <motion.section
                    ref={ref}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={inView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.7 }}
                >
                    <h2 className={s.title}>Как будет проходить обучение</h2>
                    <div className={s.card} data-module={moduleNum}>
                        <div className={s.left_block}>
                            {
                                learnings.slice(0, 3).map((el, i) => (
                                    <motion.div
                                        initial={{ opacity: 0, x: -50 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{
                                            type: "spring",
                                            stiffness: 300,
                                            duration: 0.7 + +`0.${i}`,
                                            delay: 0.5 + +`0.${i}`
                                        }}
                                        style={el.image ? { backgroundImage: `url(${backUrl + el?.image})` } : {}}
                                        key={el.id} className={s.card_info}>
                                        <h2>{el.title}</h2>
                                        <p>{el.description}</p>
                                    </motion.div>
                                ))
                            }
                        </div>
                        <div className={s.right_block}>
                            {
                                learnings.slice(3).map((el, i) => (
                                    <motion.div
                                        initial={{ opacity: 0, x: 50 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{
                                            type: "spring",
                                            stiffness: 300,
                                            duration: 0.6 + +`0.${i}`,
                                            delay: 0.8 + +`0.${i}`
                                        }}
                                        style={el.image ? { backgroundImage: `url(${backUrl + el?.image})` } : {}}
                                        key={el.id} className={s.card_info}>
                                        <h2>{el.title}</h2>
                                        <p>{el.description}</p>
                                    </motion.div>
                                ))
                            }
                        </div>
                    </div>
                </motion.section>
            }
        </>
    );
};

export default CourseOverview;