import React, { FC } from 'react';
import s from './TargetAudience.module.scss'
import { IModule } from '../../redux/types';
import { backUrl } from '../../utils';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'


interface TargetAudienceProps {
    for_whos: IModule['for_whos']
}

const TargetAudience: FC<TargetAudienceProps> = ({ for_whos }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });
    return (
        <>
            {
                for_whos.length > 0 &&
                <motion.section
                    ref={ref}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={inView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.5, delay: 0.3 }}
                    className={s.target_audience}>
                    <h2 className={s.title}>
                        Кому подойдет данный курс
                    </h2>

                    <div className={s.cards}>
                        {
                            for_whos.map((el, i) => (
                                <motion.div
                                    initial={{ opacity: 0, x: i % 2 === 0 ? -150 : 150 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.6 + +`0.${i}`, delay: 0.5 + +`0.${i}5` }}
                                    key={el.id} className={s.card}>
                                    <img src={backUrl + el.icon} alt="cardIcon" />
                                    <motion.h2
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={inView ? { opacity: 1, y: 0 } : {}}
                                        transition={{ duration: 0.6, delay: 0.7 }}
                                    >{el.title}</motion.h2>
                                    <motion.p
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={inView ? { opacity: 1, y: 0 } : {}}
                                        transition={{ duration: 0.6, delay: 0.8 }}
                                    >{el.description}</motion.p>
                                </motion.div>
                            ))
                        }
                    </div>
                </motion.section>
            }
        </>
    );
};

export default TargetAudience;