import React, { FC } from 'react';
import './HeroSwiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules';
import { motion } from 'framer-motion';
import { useAppSelector } from '../../../../../redux/hooks';
import { backUrl } from '../../../../../utils';
import { Link } from 'react-scroll';

interface HeroSwiperProps {
    mousePosition: { x: number, y: number }
}

const HeroSwiper: FC<HeroSwiperProps> = ({ mousePosition }) => {
    const { info } = useAppSelector(state => state.info)

    return (
        <div className='swiper_wrappper'>
            {
                info &&
                <Swiper
                    spaceBetween={30}
                    effect={'fade'}
                    modules={[EffectFade, Autoplay]}
                    className="swiper_hero"
                    autoplay={info?.swipers.length ? true : false}
                    speed={1100}
                >
                    <SwiperSlide>
                        <img src={backUrl + info?.hero_bg_image} alt="swiper slide" />
                    </SwiperSlide>
                    {
                        info?.swipers && info?.swipers.length > 0 &&
                        info?.swipers.map(el => (
                            <SwiperSlide key={el.id}>
                                <img src={backUrl + el.image} alt="swiper slide" />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            }

            <div className='bg_fone'>
                <motion.div
                    className='swiper_text_wrapper'
                    style={{
                        perspective: 1000,
                    }}
                >
                    <motion.div
                        animate={{ rotateX: mousePosition.y, rotateY: mousePosition.x }}
                        transition={{ type: "spring", stiffness: 300, damping: 60 }}
                    >
                        <motion.h1
                            className='animated_text'
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            {info?.hero_name}
                        </motion.h1>

                        <motion.p
                            className='animated_text'
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.3 }}
                        >
                            {info?.hero_description}
                        </motion.p>

                        <Link
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                            to='places'>
                            <motion.button
                                className='box start_btn animated_text'
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                whileTap={{ scale: 0.6 }}
                                transition={{ type: "spring", stiffness: 600, damping: 9, duration: 0.8 }}
                            >
                                С чего начать
                            </motion.button>
                        </Link>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default HeroSwiper;
