import React, { FC } from 'react';
import s from './ProgramElements.module.scss'
import ProgramElementItem from './ProgramElementItem/ProgramElementItem';
import { IModuleThree } from '../../../redux/types';

interface ProgramElementsProps {
    general_programs: IModuleThree['general_programs']
}

const ProgramElements: FC<ProgramElementsProps> = ({ general_programs }) => {
    return (
        <>
            {
                general_programs.length > 0 &&
                <>
                    <h2 className={s.title}>Общие элементы программы</h2>
                    <div className={s.wrapper}>
                        <div className={s.left_block}>
                            {
                                general_programs.slice(0, 2).map((el, i) => (
                                    <ProgramElementItem i={i} {...el} className={i === 1 ? s.small_height : undefined} />
                                ))
                            }
                        </div>
                        <div className={s.right_block}>
                            {
                                general_programs.slice(2).map((el, i) => (
                                    <ProgramElementItem i={i + 2} {...el} className={i === 0 ? s.small_height : undefined} />
                                ))
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default ProgramElements;
