import React, { FC, useRef, useState } from 'react';
import './Slider.scss'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import SliderElement from './SliderElement/SliderElement';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import { IModule } from '../../../../redux/types';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface SliderProps {
    general_programs: IModule['general_programs']
}

const Slider: FC<SliderProps> = ({ general_programs }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef<SwiperRef>(null)

    const handleSlideChange = () => {
        if (swiperRef.current) {
            setActiveIndex(swiperRef.current.swiper.realIndex);
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const checkInnerWidth = () => {
        const client = window.innerWidth
        if (client > 992) {
            return 4
        } else if (client < 767) {
            return 1.2
        } else if (client < 992) {
            return 2
        }
    }


    return (
        <>
            {
                general_programs.length > 0 &&
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 1 }}
                    className='slider_wrapper'>
                    <h2 className={`title`}>Общие элементы программы</h2>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={window.innerWidth > 992 ? 30 : 15}
                        slidesPerView={checkInnerWidth()}
                        onSlideChange={handleSlideChange}
                        className='programm_swiper'
                        centerInsufficientSlides={general_programs.length <= 2}
                    >
                        {
                            general_programs.map((el, i) => <SwiperSlide key={el.id}>
                                <SliderElement i={i} inView={inView} el={el} />
                            </SwiperSlide>
                            )
                        }

                    </Swiper>
                    <div className='btns_swiper_wrapper'>
                        <button
                            aria-label='Предыдущий'
                            disabled={activeIndex === 0}
                            onClick={() => swiperRef.current?.swiper.slidePrev()}
                        ><IoArrowBackOutline /></button>
                        <button
                            aria-label='Следующий'
                            disabled={
                                activeIndex === general_programs.length - Math.floor(checkInnerWidth()!)
                                ||
                                general_programs.length <= 2
                            }
                            onClick={() => swiperRef.current?.swiper.slideNext()}
                        ><IoArrowForwardOutline /></button>
                    </div>

                </motion.div>
            }
        </>
    );
};

export default Slider;