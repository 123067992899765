import React, { FC } from 'react';
import s from './ContactForm.module.scss'
import ShowFeedback from '../ShowFeedback/ShowFeedback';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface ContactFormProps {
    moduleNum?: string
}

const ContactForm: FC<ContactFormProps> = ({ moduleNum }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={inView ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.6, delay: 0.5 }}
            data-module={moduleNum ? moduleNum : '0'} className={`${s.form} container`}>
            <div className={s.title}>
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.7, delay: 0.8 }}
                >Остались вопросы?</motion.h2>
                <motion.p
                    initial={{ opacity: 0, y: -50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.7, delay: 1 }}
                >Оставьте свой контактный номер телефона и мы свяжемся с вами в течении 10 минут</motion.p>
            </div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={inView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.8, delay: 1 }}
                className={s.btn_wrapper}>
                <ShowFeedback>
                    Заказать обратную связь
                </ShowFeedback>
            </motion.div>
        </motion.div>
    );
};

export default ContactForm;