import React, { FC, useEffect } from 'react';
import TradeInfoMain from './TradeInfoMain/TradeInfoMain';
import ModuleInfoCard from './ModuleInfoCard/ModuleInfoCard';
import InfoColumn from './InfoColumn/InfoColumn';
import { scrollToTop } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchByProgram } from '../../redux/slices/programSlice';
import { Helmet } from 'react-helmet-async';


const Program: FC = () => {
    const dispatch = useAppDispatch()
    const { program } = useAppSelector(state => state.program)

    useEffect(() => {
        scrollToTop()
        if (!program) {
            dispatch(fetchByProgram())
        }
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>Программа | Клуб новаторов</title>
                <meta name="description" content={program?.description} />
                <meta property="og:title" content="Программа | Клуб новаторов" />
                <meta property="og:description" content="Мы обучаем трейдингу с фокусом на Форекс. Наш курс поможет вам развить осознанные торговые стратегии и навыки самостоятельной торговли." />
                <meta property="og:url" content="https://itsplatform.net/program" />
                <link rel="canonical" href="https://itsplatform.net/program" />
            </Helmet>
            {
                program &&
                <div className='container'>
                    <TradeInfoMain program={program} />
                    {
                        program.modules.length > 0 &&
                        program.modules.map((el, i) => (
                            <section key={el.id}>
                                <ModuleInfoCard key={el.id} el={el} moduleNum={1 + i} />
                            </section>
                        ))
                    }
                    <InfoColumn modules={program.modules} title={program.bottom_title} />
                </div>
            }
        </>
    );
};

export default Program;