import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import s from './ModalHoc.module.scss'
import { motion } from 'framer-motion'

interface ModalHocProps {
    children: ReactNode
    show?: boolean
    hide?: () => void
}

const ModalHoc: FC<ModalHocProps> = ({ children, show, hide }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(show);

    useEffect(() => {
        if (isPopupOpen) {
            window.history.pushState(null, "", window.location.href);

            const handlePopstate = () => {
                setIsPopupOpen(false);
                hide && hide();
            };


            window.addEventListener('popstate', handlePopstate);
            return () => {
                window.removeEventListener('popstate', handlePopstate);
            };
        }


    }, [isPopupOpen, hide]);

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.addEventListener('keydown', (e) => {
            if (e.code === "Escape") {
                hide && hide()
            }
        })

        return () => {
            document.body.style.overflow = 'auto'
            document.removeEventListener('keydown', () => { })
        }
    }, [hide])

    useEffect(() => {
        setIsPopupOpen(show);
    }, [show]);


    return ReactDOM.createPortal(
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className={s.wrapper} onClick={hide}>
            <div className={s.container} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </motion.div>,
        document.body
    );
};

export default ModalHoc;