import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getFAQHome } from '../../../../redux/slices/homeSlice';
import FAQ from '../../../../Components/FAQ/FAQ';

const FAQHome: FC = () => {
    const dispatch = useAppDispatch()
    const { faq } = useAppSelector(state => state.home)

    useEffect(() => {
        if (faq.length <= 0) {
            dispatch(getFAQHome())
        }
    }, [dispatch])

    return (
        <>
            {
                faq.length > 0 &&
                <FAQ arr={faq} />
            }
        </>
    );
};

export default FAQHome;