import React, { FC } from 'react';
import Hero from './Sections/Hero/Hero';
import AboutUS from './Sections/AboutUS/AboutUS';
import WhyWe from './Sections/WhyWe/WhyWe';
import { LayoutGroup } from 'framer-motion';
import Forex from './Sections/Forex/Forex';
import Places from './Sections/Places/Places';
import Study from './Sections/Study/Study';
import Mentors from './Sections/Mentors/Mentors';
import Reviews from './Sections/Reviews/Reviews';
import FAQHome from './Sections/FAQHome/FAQHome';
import Modules from './Sections/Modules/Modules';
import ContactForm from '../../Components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

const Home: FC = () => {
    return (
        <>
            <Helmet>
                <title>Главная | Клуб новаторов</title>
                <meta property="og:title" content={`Главная | Клуб новаторов`} />
                <meta property="og:url" content="https://itsplatform.net" />
                <link rel="canonical" href="https://itsplatform.net" />
            </Helmet>
            <LayoutGroup id='home'>
                <Hero />
                <AboutUS />
                <WhyWe />
                <Forex />
                <Places />
                <Study />
                <Modules />
                <Mentors />
                <Reviews />
                <FAQHome />
                <ContactForm />
            </LayoutGroup>
        </>
    );
};

export default Home;