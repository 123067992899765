import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { INullable, IProgram } from "../types"
import { itsAPI } from "../../axios"

type ProgramState = {
    loading: boolean
    error: INullable<string>
    program: INullable<IProgram>
}

const initialState: ProgramState = {
    error: null,
    loading: false,
    program: null,
}

export const fetchByProgram = createAsyncThunk<IProgram, void, { rejectValue: string }>(
    'program/fetchByProgram',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.program()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data as IProgram

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByProgram.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByProgram.fulfilled, (state, action) => {
            state.program = action.payload
            state.loading = false
        })
        addCase(fetchByProgram.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //============================================================
    }
})

export default programSlice.reducer