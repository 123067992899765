import React, { FC, useEffect, useRef, useState } from 'react';
import './Reviews.scss'
import { Element } from 'react-scroll';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getAllReviews } from '../../../../redux/slices/homeSlice';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import AnimateSVG from './AnimateSVG/AnimateSVG';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

const Reviews: FC = () => {
    const { reviews } = useAppSelector(state => state.home)
    const [activeIndex, setActiveIndex] = useState(0);
    const dispatch = useAppDispatch()
    const swiperRef = useRef<SwiperRef>(null)

    const handleSlideChange = () => {
        if (swiperRef.current) {
            setActiveIndex(swiperRef.current.swiper.realIndex);
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const checkInnerWidth = () => {
        const client = window.innerWidth
        if (client > 992) {
            return 3
        } else if (client < 767) {
            return 1.2
        } else if (client < 992) {
            return 2
        }
    }

    useEffect(() => {
        if (reviews.length <= 0) {
            dispatch(getAllReviews())
        }
    }, [dispatch])

    return (
        <Element name='reviews'>
            {
                reviews.length > 0 &&
                <motion.section
                    ref={ref}
                    initial={{ opacity: 0, x: -150 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.8, delay: 0.3 }}
                    className='container reviews_wrapper'>
                    <h2 className='reviews_title'>Отзывы</h2>
                    <div className='reviews_swiper_wrapper'>
                        <Swiper
                            ref={swiperRef}
                            slidesPerView={checkInnerWidth()}
                            centerInsufficientSlides={reviews.length <= 2}
                            spaceBetween={window.innerWidth > 992 ? 30 : 15}
                            modules={[]}
                            className="reviews_swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {
                                reviews.map((el, i) => (
                                    <SwiperSlide key={el.id}>
                                        <div className='reviews_slide_wrapper'>
                                            <motion.h3
                                                initial={{ opacity: 0, x: 50 }}
                                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                                transition={{ duration: 0.5, delay: 0.5 }}
                                                className='review_name'>{el.name}</motion.h3>
                                            <motion.p
                                                initial={{ opacity: 0, x: 50 }}
                                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                                transition={{ duration: 0.5, delay: 0.6 }}
                                                title={el.review}
                                                className='rewiew_descr'>
                                                {
                                                    el.review.length > 250
                                                        ? el.review.slice(0, 250) + '...'
                                                        : el.review
                                                }
                                            </motion.p>
                                            <AnimateSVG i={i} />
                                        </div>
                                    </SwiperSlide>))
                            }
                        </Swiper>
                        <div className='btns_swiper_wrapper'>
                            <button
                                disabled={activeIndex === 0}
                                onClick={() => swiperRef.current?.swiper.slidePrev()}
                            ><IoArrowBackOutline /></button>
                            <button
                                disabled={
                                    activeIndex === reviews.length - Math.floor(checkInnerWidth()!)
                                    ||
                                    reviews.length <= 2
                                }
                                onClick={() => swiperRef.current?.swiper.slideNext()}
                            ><IoArrowForwardOutline /></button>
                        </div>
                    </div>
                </motion.section>
            }
        </Element>
    );
};

export default Reviews;