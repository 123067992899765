import React, { FC } from 'react';
import s from './Weeks.module.scss';
import ArrowRight from './ArrowRight/ArrowRight';
import WeekItem from './WeekItem/WeekItem';
import { IModule } from '../../../redux/types';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface WeeksProps {
    weeks: IModule['weeks'];
}

const Weeks: FC<WeeksProps> = ({ weeks }) => {

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const containerWeeks = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.3,
                duration: 0.4,
            }
        }
    };

    const itemWeek = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };


    return (
        <>
            {weeks.length > 0 && (
                <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : ''}
                    variants={containerWeeks}
                >
                    {[...weeks]
                        .sort((a, b) => a.order - b.order)
                        .map((el, index) => (
                            <motion.div
                                variants={itemWeek}
                                key={el.id} className={s.study_plan}>
                                <div className={s.first_week}>
                                    <div className={s.week_title}>
                                        <h2>{el.name}</h2>
                                    </div>

                                    {index === 0 && <ArrowRight strokeColor="#42bc2b" fillColor="#42bc2b" />}
                                    {index === 1 && <ArrowRight strokeColor="#0096F3" fillColor="#0096F3" />}
                                    {index === 2 && <ArrowRight strokeColor="#B25905" fillColor="#B25905" />}
                                    {index === 3 && <ArrowRight strokeColor="#A60702" fillColor="#A60702" />}
                                    {index > 3 && <ArrowRight strokeColor="#6e34eb" fillColor="#6e34eb" />}

                                    <div className={s.arrow_down}></div>
                                </div>

                                <motion.div
                                    initial="hidden"
                                    animate={inView ? "visible" : ''}
                                    variants={containerWeeks}
                                    className={s.week_cards}>
                                    {el.lessons.length > 0 &&
                                        [...el.lessons]
                                            .sort((a, b) => a.order - b.order)
                                            .map((lesson) => <WeekItem key={lesson.id} lesson={lesson} />)}
                                </motion.div>
                            </motion.div>
                        ))}
                </motion.div>
            )}
        </>
    );
};

export default Weeks;
