import React, { FC, useState, useEffect } from 'react';
import s from './WhyWe.module.scss';
import WhyCard from './WhyCard/WhyCard';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from '../../../../redux/hooks';
import ShowFeedback from '../../../../Components/ShowFeedback/ShowFeedback';

const WhyWe: FC = () => {
    const { info } = useAppSelector(state => state.info);

    const [isLargeScreen, setIsLargeScreen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1, // Чуть большее значение, чтобы анимация срабатывала раньше
    });

    const containerVariants = isLargeScreen
        ? {
            hidden: { opacity: 1, scale: 0 },
            visible: {
                opacity: 1,
                scale: 1,
                transition: {
                    delayChildren: 0.3,
                    staggerChildren: 0.2,
                    duration: 0.3,
                },
            },
        }
        : undefined; // Передаем undefined, если анимацию отключаем

    return (
        <>
            {info?.why_we && info?.why_we.length > 0 && (
                <section className={`container ${s.wrapper}`}>
                    <h2 className={s.title}>Почему мы?</h2>
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={containerVariants} // Применяем варианты анимации, либо undefined
                        className={s.cards_wrapper}
                    >
                        {info?.why_we.map(el => (
                            <WhyCard key={el.id} {...el} />
                        ))}
                    </motion.div>
                    <span className={s.btn_wrapper}>
                        <ShowFeedback>Оформить подписку</ShowFeedback>
                    </span>
                </section>
            )}
        </>
    );
};

export default WhyWe;
