import React, { FC, useState } from 'react';
import s from './CardInfo.module.scss'
import { IModule } from '../../redux/types';
import { motion } from 'framer-motion'
import { Link } from 'react-scroll';
import { backUrl } from '../../utils';

interface CardInfoProps {
    title: IModule['title']
    description: IModule['description']
    duration: IModule['duration']
    amount: IModule['lesson_amount']
    main_banner: IModule['main_banner']
    moduleNum: string
}

const CardInfo: FC<CardInfoProps> = ({ amount, description, duration, title, main_banner, moduleNum }) => {
    const [mousePosition, setMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });


    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const x = (e.clientX / window.innerWidth - 0.8) * -20;
        const y = (e.clientY / window.innerHeight - 0.8) * 20;
        setMousePosition({ x, y });
    };

    return (
        <motion.article
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7 }}
            className={s.card_bg}
            data-module={moduleNum}
            onMouseMove={handleMouseMove}
            style={{
                // width: '95%',
                // height: '700px',
                perspective: 1000,
                // margin: "40px 0",
            }}
        >
            <div className={s.bg}>
                <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7, delay: 0.8 }}
                    className={s.banner} src={backUrl + main_banner} alt="Module banner" />
                <div className={s.module_info}>
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                        className={s.module_num}>
                        <p>Модуль 1</p>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.9 }}
                        className={s.duration}>

                        <div className={s.duration_item}>
                            <p>Продолжительность:<span> {duration}</span></p>
                        </div>

                        <div className={s.duration_item}>
                            <p>Количество уроков:<span> {amount}</span></p>
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    // initial={{ opacity: 0, x: -50 }}
                    // animate={{ opacity: 1, x: 0 }}
                    // transition={{ duration: 0.8, delay: 0.3 }}
                    animate={{ rotateX: mousePosition.y, rotateY: mousePosition.x }}
                    transition={{ type: "spring", stiffness: 300, damping: 60 }}
                    className={s.info_card}>
                    <motion.h2
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.5 }}
                    >{title}</motion.h2>
                    <motion.p
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.6 }}
                    >{description}</motion.p>
                    <Link
                        to={"program"}
                        spy={true}
                        smooth={true}
                        offset={150}
                        duration={500}
                    >
                        <motion.button
                            className='box start_btn animated_text'
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            whileTap={{ scale: 0.6 }}
                            transition={{ type: "spring", stiffness: 600, damping: 9, duration: 0.8 }}
                        >Программа</motion.button>
                    </Link>
                </motion.div>
            </div>
        </motion.article>
    );
};

export default CardInfo;