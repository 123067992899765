import React, { FC } from 'react';
import s from './StudyPlan.module.scss'
import Weeks from './Weeks/Weeks';
import { IModule } from '../../redux/types';
import { Element } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'

interface StudyPlanProps {
    for_who: IModule['for_who']
    for_who_answer: IModule['for_who']
    weeks: IModule['weeks']
    amount: IModule['lesson_amount']
    duration: IModule['duration']
    moduleNum: string
}

const StudyPlan: FC<StudyPlanProps> = ({ for_who, for_who_answer, weeks, amount, duration, moduleNum }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <Element name={'program'}>
            <section ref={ref}>
                <div data-module={moduleNum} className={s.course_info}>
                    <div className={s.left_block}>
                        <motion.h2
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className={s.title}>Программа обучения</motion.h2>
                        <motion.span
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >{for_who}</motion.span>
                        <motion.p
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >{for_who_answer}</motion.p>
                    </div>
                    <div className={s.duration}>
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className={s.duration_item}>
                            <p><span>Продолжительность: </span>{duration}</p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className={s.duration_item}>
                            <p><span>Количество уроков: </span> {amount}</p>
                        </motion.div>
                    </div>
                </div>
                <Weeks weeks={weeks} />
            </section>
        </Element>
    );
};

export default StudyPlan;