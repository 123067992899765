import React, { FC, useState } from 'react';
import '../Mentors.scss'
import { IMentors } from '../../../../../redux/types';
import { backUrl } from '../../../../../utils';
import ModalHoc from '../../../../../Components/ModalHoc/ModalHoc';
import ModalMentor from '../ModalMentor/ModalMentor';
import { motion } from 'framer-motion'

export interface MentorsProps {
    el: IMentors
    inView?: boolean
}

const SlideMentors: FC<MentorsProps> = ({ el, inView }) => {
    const [show, setShow] = useState(false)

    const hide = () => {
        setShow(false)
    }

    return (
        <>
            <div onClick={() => setShow(true)} className='slide_wrapper'>
                <motion.img
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    src={backUrl + el.image_url} alt={`${el.name}`} />
                <motion.h3
                    initial={{ opacity: 0, x: -50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    className='mentor_name'>{el.name}</motion.h3>
                <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className='mentor_descr'>
                    {
                        el.description.length > 150
                            ? el.description.slice(0, 150) + '...'
                            : el.description
                    }
                </motion.p>
            </div>
            {
                show &&
                <ModalHoc show={show} hide={hide}>
                    <ModalMentor hide={hide} el={el} />
                </ModalHoc>
            }
        </>
    );
};

export default SlideMentors;