import React, { FC } from 'react';
import s from './ModuleCard.module.scss'
import { IModuleCard } from '../../redux/types';
import { backUrl } from '../../utils';
import Rating from './Rating/Rating';
import ShowFeedback from '../ShowFeedback/ShowFeedback';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface ModuleCardProps {
    el: IModuleCard
    i: number
}

const ModuleCard: FC<ModuleCardProps> = ({ el, i }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: (0.4 + +`0.${i}`) }}
            className={s.card}>
            <div className={s.top_block}>
                <span className={s.comp_name}>ITS</span>
                <h3 className={s.title}>{el.card_name}</h3>
            </div>
            <div className={s.icon_wrapper}>
                <img src={backUrl + el.card_icon} alt={`${el.card_name} иконка`} />
            </div>
            <div className={s.info_wrapper}>
                <h4 className={s.subtitle}>{el.card_title}</h4>
                <ul className={s.skills}>
                    <li>{el.card_item_1}</li>
                    <li>{el.card_item_2}</li>
                    <li>{el.card_item_3}</li>
                    <li>{el.card_item_4}</li>
                </ul>
                <span className={s.for_who}>{el.card_for_who}</span>
                <h4 className={s.duration}>Длительность <span>{el.duration}</span></h4>
                <div className={s.rating_wrapper}>
                    <Rating label="Знания" value={el.card_knowledge} max={10} />
                    <Rating label="Навыки" value={el.card_skills} max={10} />
                </div>
                <p className={s.description}>{el.card_description}</p>
                <div className={s.btns_wrapper}>
                    <ShowFeedback>Купить курс</ShowFeedback>
                    <Link
                        className={s.link}
                        to={el.id === 1 ? '/module-one' : el.id === 2 ? '/module-two' : '/module-three'}>Подробнее</Link>
                </div>
            </div>
        </motion.div>
    );
};

export default ModuleCard;