import React, { FC } from 'react';
import s from './BenefitsItem.module.scss'
import { IAdvantages } from '../../../../redux/types';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface BenefitsItemProps {
    id: IAdvantages['id']
    title: IAdvantages['title']
    description: IAdvantages['description']
}

const BenefitsItem: FC<BenefitsItemProps> = ({ description, title }) => {
    const itemBenef = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={ref}
            variants={itemBenef}
            className={s.item}>
            <motion.h2
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8, delay: 0.6 }}
                className={s.item_title}>{title}</motion.h2>
            <motion.p
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.8, delay: 0.8 }}
                className={s.item_p}>{description}</motion.p>
        </motion.div>
    );
};

export default BenefitsItem;