import React, { FC } from 'react';
import s from './ProgramElementItem.module.scss';
import { IGeneralPrograms } from '../../../../redux/types';
import { backUrl } from '../../../../utils';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface ProgramElementItemProps {
    className?: string;
    id: IGeneralPrograms['id']
    image: IGeneralPrograms['image']
    description: IGeneralPrograms['description']
    i: number
}

const ProgramElementItem: FC<ProgramElementItemProps> = ({ className, description, image, i }) => {

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    console.log(i);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: i === 0 || i === 1 ? -80 : 80 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 + +`0.${i}`, delay: 0.5 + +`0.${i}` }}
            style={{ backgroundImage: `url(${backUrl + image})` }}
            className={`${s.block_item} ${className ? className : ''}`}>
            <div className={s.info}>
                <motion.p
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.7 + +`0.${i}`, delay: 0.7 + +`0.${i}` }}
                >{description}</motion.p>
            </div>
        </motion.div>
    );
};

export default ProgramElementItem;
