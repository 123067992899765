import React, { FC } from 'react';
import s from './Header.module.scss'
import { Link as RouterLink } from 'react-router-dom';
import accLogo from '../../assets/images/account.png'
import Navigation from '../Navigation/Navigation';
import { useAppSelector } from '../../redux/hooks';
import { backUrl } from '../../utils';
import BurgerMenu from './BurgerMenu/BurgerMenu';

const Header: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <header className={s.header}>
            <BurgerMenu />
            <div className={`container ${s.header_wrapper}`}>
                <RouterLink className={s.logo} to={'/'}>
                    {info && <img src={backUrl + info?.logo} alt="logo" />}
                </RouterLink>
                <nav className={s.navigation}>
                    <Navigation />
                </nav>
                <a
                    className={s.account_link}
                    href='http://localhost:3000'>
                    <img src={accLogo} alt="Переход на платформу" />
                    Кабинет
                </a>
            </div>
        </header>
    );
};

export default Header;