import React, { FC } from 'react';
import s from './SliderElement.module.scss'
import { IGeneralPrograms } from '../../../../../redux/types';
import { backUrl } from '../../../../../utils';
import { motion } from 'framer-motion'

interface SliderElementProps {
    el: IGeneralPrograms
    inView: boolean
    i: number
}

const SliderElement: FC<SliderElementProps> = ({ el, i, inView }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 + +`0.${i}`, delay: 0.5 + +`0.${i}` }}
            className={s.card}>
            <div>
                <img src={backUrl + el.image} alt="cardImg" />
            </div>
            <div className={s.info}>
                <motion.h2
                    initial={{ opacity: 0, x: 70 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.6 + +`0.${i}`, delay: 0.6 + +`0.${i}` }}
                >{el.title}</motion.h2>
                <motion.p
                    initial={{ opacity: 0, x: 70 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.7 + +`0.${i}`, delay: 0.7 + +`0.${i}` }}
                >{el.description.length > 130 ? el.description.slice(0, 130) + '...' : el.description}</motion.p>
            </div>
        </motion.div>
    );
};

export default SliderElement;