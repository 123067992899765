import React, { FC } from 'react';
import s from './LearningFormat.module.scss'
import { IModule } from '../../redux/types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'

interface LearningFormatProps {
    format_learnings: IModule['format_learnings']
    moduleNum: string
}

const LearningFormat: FC<LearningFormatProps> = ({ format_learnings, moduleNum }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const waveVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.55, // задержка для каждой буквы
                duration: 1.2,
                // repeat: Infinity,
                // ease: 'easeInOut'
            },
        }),
    };


    // Эффект написания текста
    const typingVariants = {
        hidden: { width: "0px" },
        visible: {
            width: "auto",
            transition: {
                duration: 3,
                delay: 1,
                repeat: Infinity,
                // ease: 'easeInOut'
                // ease: "steps(10, end)", // имитация печати по шагам
            }
        }
    };

    return (
        <>
            {format_learnings.length > 0 && (
                <motion.section
                    ref={ref}
                    initial={{ opacity: 0, y: 50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6, delay: 0.5 }}
                    className={s.wrapper}
                >
                    <h2 className={s.title}>Формат обучения</h2>

                    <div className={s.info} data-module={moduleNum}>
                        <div className={s.left_block}>
                            <motion.h2 className={s.logo}>
                                {"ITS".split("").map((char, index) => (
                                    <motion.span
                                        key={index}
                                        custom={index}
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={waveVariants}
                                        style={{ color: "inherit" }}
                                    >
                                        {char}
                                    </motion.span>
                                ))}
                            </motion.h2>

                            <motion.p
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={typingVariants}
                                style={{ overflow: "hidden", whiteSpace: "nowrap", borderRight: "2px solid", paddingRight: "5px" }}
                            >
                                Innovators Traiding System Platform
                            </motion.p>
                        </div>

                        <div className={s.right_block}>
                            {format_learnings.map((el, i) => (
                                <motion.div
                                    initial={{ opacity: 0, x: 100 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.8 + +`0.${i}`, delay: 0.7 + +`0.${i}` }}
                                    key={el.id}
                                    className={s.card}
                                >
                                    <h3>{el.title}</h3>
                                    <div className={s.info_items}>
                                        <p>{el.number}</p>
                                        <span>{el.sup_title}</span>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </motion.section>
            )}
        </>
    );
};

export default LearningFormat;
