import React, { FC } from 'react';
import s from './ModuleInfoCard.module.scss'
import { IProgramModule } from '../../../redux/types';
import { backUrl } from '../../../utils';
import ShowFeedback from '../../../Components/ShowFeedback/ShowFeedback';
import { Link } from 'react-router-dom';

interface ModuleInfoCardProps {
    el: IProgramModule
    moduleNum: number
}

const ModuleInfoCard: FC<ModuleInfoCardProps> = ({ el, moduleNum }) => {
    return (
        <div className={s.card}
            data-module={moduleNum}
            style={{ backgroundImage: `url(${backUrl + el.program_slide_bg})` }} >
            <div className={s.info}>
                <div className={s.module_num}>
                    <p>{el.card_name}</p>
                </div>
                <div className={s.mod_time}>
                    <p> <span>Продолжительность:</span> {el.duration}</p>
                </div>
            </div>

            <div className={s.first_steps}>
                <h2>{el.card_title}</h2>
                <p>{el.description}</p>
                <div className={s.btn_group}>
                    <span>
                        <ShowFeedback>Записаться на курс</ShowFeedback>
                    </span>
                    <Link
                        to={moduleNum === 1 ? '/module-one' : moduleNum === 2 ? '/module-two' : '/module-three'}
                    >
                        <button className={s.link}>Программа</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ModuleInfoCard;