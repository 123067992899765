import React, { FC } from 'react';
import s from './WeekItem.module.scss'
import { ILessons } from '../../../../redux/types';
import { motion } from 'framer-motion'

interface WeekItemProps {
    lesson: ILessons
}

const WeekItem: FC<WeekItemProps> = ({ lesson }) => {
    const { lesson_themes, title } = lesson

    const checkInnerWidth = () => {
        const client = window.innerWidth
        if (client > 992) {
            return 0.5
        } else if (client < 992) {
            return 1
        }
    }

    const itemWeek = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: checkInnerWidth()
        }
    };

    return (
        <motion.div
            variants={itemWeek}
            whileHover={{ opacity: 1, scale: [null, 1.15, 1.1] }}
            transition={{ duration: 0.4 }}
            className={s.card}>
            <h2>{title}</h2>
            <div className={s.card_info}>
                <div className={s.info_item}>
                    <ul className={s.dot}>
                        {
                            lesson_themes.length > 0 &&
                            lesson_themes.map(el => (
                                <li key={el.id}>{el.title}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </motion.div>
    );
};

export default WeekItem;