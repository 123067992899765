import React, { FC, useEffect } from 'react';
import s from './Modules.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getModulesCard } from '../../../../redux/slices/homeSlice';
import ModuleCard from '../../../../Components/ModuleCard/ModuleCard';

const Modules: FC = () => {
    const dispatch = useAppDispatch()
    const { modules } = useAppSelector(state => state.home)

    useEffect(() => {
        if (modules.length <= 0) {
            dispatch(getModulesCard())
        }
    }, [dispatch])

    return (
        <>
            {
                modules.length > 0 &&
                <section className={`${s.wrapper} container`}>
                    {
                        modules.map((el, i) => <ModuleCard key={el.id} el={el} i={i} />)
                    }
                </section>
            }
        </>
    );
};

export default Modules;