import React, { FC } from 'react';
import s from './Places.module.scss'
import { useAppSelector } from '../../../../redux/hooks';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { Element } from 'react-scroll';

const Places: FC = () => {
    const { info } = useAppSelector(state => state.info)

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    return (
        <Element name='places'>
            {
                info && info?.places.length > 0 &&
                <section className={`${s.places} container`}>
                    <p className={s.description}>
                        Место, где вы научитесь зарабатывать и управлять капиталом с помощью трейдинга.
                    </p>
                    <motion.div
                        ref={ref}
                        className={s.wrapper}>
                        {
                            info.places.map((el, i) => (
                                <motion.div
                                    initial={i === 0 || i === 1 ? { opacity: 0, y: -150 } : { opacity: 0, y: 150 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 0.6, delay: 0.3 + +`0.${i}9` }}
                                    key={el.id} className={s.card}>
                                    <h4 className={s.card_title}>{el.title}</h4>
                                    <p className={s.card_description}>{el.description}</p>
                                </motion.div>
                            ))
                        }
                    </motion.div>
                </section>
            }
        </Element>
    );
};

export default Places;