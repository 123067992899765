import React, { FC } from 'react';
import s from './CourseBenefits.module.scss'
import BenefitsItem from './BenefitsItem/BenefitsItem';
import { IModuleTwo } from '../../../redux/types';
import { backUrl } from '../../../utils';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

interface CourseBenefitsProps {
    advantages: IModuleTwo['advantages']
    advantages_bg: IModuleTwo['advantages_bg']
}

const CourseBenefits: FC<CourseBenefitsProps> = ({ advantages, advantages_bg }) => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const containerBenef = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.3,
                duration: 0.4,
            }
        }
    };
    return (
        <>
            {
                advantages.length > 0 &&
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={inView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    className={s.wrapper}
                >
                    <h2 className={s.title}>Преимущества курса</h2>
                    <motion.div
                        initial="hidden"
                        animate={inView ? "visible" : ''}
                        variants={containerBenef}
                        style={{ backgroundImage: `url(${backUrl + advantages_bg})` }} className={s.bg}>
                        {
                            advantages.map((el, i) => <BenefitsItem key={el.id} {...el} />)
                        }
                    </motion.div>
                </motion.div>
            }
        </>
    );
};

export default CourseBenefits;