import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IModule, IModuleThree, IModuleTwo, INullable } from "../types"
import { itsAPI } from "../../axios"

type InfoState = {
    loading: boolean
    error: INullable<string>
    module_one: INullable<IModule>
    module_two: INullable<IModuleTwo>
    module_three: INullable<IModuleThree>
}

const initialState: InfoState = {
    error: null,
    loading: false,
    module_one: null,
    module_two: null,
    module_three: null,
}

export const fetchByModuleOne = createAsyncThunk<IModule, void, { rejectValue: string }>(
    'modules/fetchByModuleOne',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.moduleOne()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data as IModule

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByModuleTwo = createAsyncThunk<IModuleTwo, void, { rejectValue: string }>(
    'modules/fetchByModuleTwo',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.moduleTwo()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data as IModuleTwo

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByModuleThree = createAsyncThunk<IModuleThree, void, { rejectValue: string }>(
    'modules/fetchByModuleThree',
    async (_, { rejectWithValue }) => {
        try {
            const res = await itsAPI.moduleThree()
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data as IModuleThree

        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)


const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByModuleOne.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByModuleOne.fulfilled, (state, action) => {
            state.module_one = action.payload
            state.loading = false
        })
        addCase(fetchByModuleOne.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //============================================================
        addCase(fetchByModuleTwo.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByModuleTwo.fulfilled, (state, action) => {
            state.module_two = action.payload
            state.loading = false
        })
        addCase(fetchByModuleTwo.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //============================================================
        addCase(fetchByModuleThree.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByModuleThree.fulfilled, (state, action) => {
            state.module_three = action.payload
            state.loading = false
        })
        addCase(fetchByModuleThree.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload
            }
            state.loading = false
        })
        //============================================================
    }
})

export default modulesSlice.reducer