import React, { FC } from 'react';
import s from './ModalMentor.module.scss'
import { MentorsProps } from '../SlideMentors/SlideMentors';
import { backUrl } from '../../../../../utils';
import { motion } from 'framer-motion'
import close_icon from '../../../../../assets/images/ic_round-close.svg'

const ModalMentor: FC<MentorsProps & { hide: () => void }> = ({ el, hide }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className={s.wrapper}>
            <img onClick={hide} className={s.close} src={close_icon} alt="close icon" />
            <video
                preload='metadata'
                controls
                controlsList="nodownload"
                src={backUrl + el.video_url}
                poster={backUrl + el.image_url}>
                Sorry, your browser doesn't support embedded videos
            </video>
            <motion.h2
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
                className={s.mentor_name}>{el.name}</motion.h2>
            <motion.p
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className={s.mentor_descr}>{el.description}</motion.p>
        </motion.div>
    );
};

export default ModalMentor;