import React, { FC, useEffect, useRef, useState } from 'react';
import './Mentors.scss'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getAllMentors } from '../../../../redux/slices/homeSlice';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import SlideMentors from './SlideMentors/SlideMentors';
import { Element } from 'react-scroll';
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

const Mentors: FC = () => {
    const { mentors } = useAppSelector(state => state.home)
    const [activeIndex, setActiveIndex] = useState(0);
    const dispatch = useAppDispatch()
    const swiperRef = useRef<SwiperRef>(null)

    const handleSlideChange = () => {
        if (swiperRef.current) {
            setActiveIndex(swiperRef.current.swiper.realIndex);
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const checkInnerWidth = () => {
        const client = window.innerWidth
        if (client > 992) {
            return 3
        } else if (client < 767) {
            return 1.2
        } else if (client < 992) {
            return 2
        }
    }

    useEffect(() => {
        if (mentors.length === 0) {
            dispatch(getAllMentors())
        }
    }, [dispatch])

    return (
        <Element name='mentors'>
            {
                mentors.length > 0 &&
                <section className='container mentors_sectons'>
                    <h2 className='mentors_title'>Спикеры</h2>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, x: 50 }}
                        animate={inView ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8, delay: 0.3 }}
                        className='swiper_wrapper'>
                        <Swiper
                            ref={swiperRef}
                            slidesPerView={checkInnerWidth()}
                            centerInsufficientSlides={mentors.length <= 2}
                            spaceBetween={window.innerWidth > 992 ? 30 : 15}
                            modules={[]}
                            className="mentors_swiper"
                            onSlideChange={handleSlideChange}
                        >
                            {
                                mentors.map(el => <SwiperSlide key={el.id}>
                                    <SlideMentors inView={inView} el={el} />
                                </SwiperSlide>)
                            }
                        </Swiper>
                        <div className='btns_swiper_wrapper'>
                            <button
                                aria-label='Предыдущий'
                                disabled={activeIndex === 0}
                                onClick={() => swiperRef.current?.swiper.slidePrev()}
                            ><IoArrowBackOutline /></button>
                            <button
                                aria-label='Следующий'
                                disabled={
                                    activeIndex === mentors.length - Math.floor(checkInnerWidth()!)
                                    ||
                                    mentors.length <= 2
                                }
                                onClick={() => swiperRef.current?.swiper.slideNext()}
                            ><IoArrowForwardOutline /></button>
                        </div>
                    </motion.div>
                </section>
            }
        </Element>
    );
};

export default Mentors;