import React, { FC, useEffect } from 'react';
import Slider from './Sections/Slider/Slider';
import FAQ from '../../Components/FAQ/FAQ';
import { scrollToTop } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchByModuleOne } from '../../redux/slices/modulesSlice';
import CardInfo from './../../Components/CardInfo/CardInfo';
import TargetAudience from './../../Components/TargetAudience/TargetAudience';
import CourseOverview from './../../Components/CourseOverview/CourseOverview';
import LearningFormat from './../../Components/LearningFormat/LearningFormat';
import StudyPlan from './../../Components/StudyPlan/StudyPlan';
import ExpectedResult from './../../Components/ExpectedResult/ExpectedResult';
import { LayoutGroup } from 'framer-motion';
import ContactForm from '../../Components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';


const ModuleOne: FC = () => {
    const { module_one } = useAppSelector(state => state.mmodules)
    const dispatch = useAppDispatch()
    const moduleNum: string = '1'

    useEffect(() => {
        scrollToTop()
        if (!module_one) {
            dispatch(fetchByModuleOne())
        }
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>Модуль 1 | Клуб новаторов</title>
                <meta name="description" content="Трейдинг с нуля: обучение основам работы на финансовых рынках, терминам и ключевым принципам для начинающих трейдеров. Готовьтесь к реальной торговле." />
                <meta property="og:title" content={`${module_one?.title} | Клуб новаторов`} />
                <meta name="og:description" content={`${module_one?.title}, ${module_one?.description}`} />
                <meta property="og:url" content="https://itsplatform.net/module-one" />
                <link rel="canonical" href="https://itsplatform.net/module-one" />
            </Helmet>
            {
                module_one &&
                <LayoutGroup id='module_one'>
                    <CardInfo
                        moduleNum={moduleNum}
                        title={module_one?.title}
                        amount={module_one?.lesson_amount}
                        duration={module_one?.duration}
                        description={module_one?.description}
                        main_banner={module_one?.main_banner}
                    />
                    <div className='container'>
                        <Slider general_programs={module_one?.general_programs} />
                        <TargetAudience for_whos={module_one?.for_whos} />
                        <CourseOverview moduleNum={moduleNum} learnings={module_one?.learnings} />
                        {/* <LearningFormat moduleNum={moduleNum} format_learnings={module_one?.format_learnings} /> */}
                        <StudyPlan
                            moduleNum={moduleNum}
                            for_who={module_one?.for_who}
                            for_who_answer={module_one?.for_who_answer}
                            weeks={module_one?.weeks}
                            amount={module_one?.lesson_amount}
                            duration={module_one?.duration}
                        />
                        <ExpectedResult
                            moduleNum={moduleNum}
                            result_bg={module_one?.result_bg}
                            results={module_one?.results}
                        />
                        {
                            module_one.faq.length > 0 &&
                            <FAQ moduleNum={moduleNum} arr={module_one.faq} />
                        }
                    </div>
                    <ContactForm moduleNum={moduleNum} />
                </LayoutGroup>
            }
        </>
    );
};

export default ModuleOne;