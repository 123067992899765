import React, { FC } from 'react';
import s from './TradeInfoMain.module.scss'
import { IProgram } from '../../../redux/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import './TradeSwiper.scss'
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules';
import { backUrl } from '../../../utils';
import ShowFeedback from '../../../Components/ShowFeedback/ShowFeedback';
import { motion } from 'framer-motion'

interface TradeInfoMainProps {
    program: IProgram
}

const TradeInfoMain: FC<TradeInfoMainProps> = ({ program }) => {

    return (
        <motion.article
            initial={{ opacity: 0, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={s.main_block}>
            <motion.div
                initial={{ opacity: 0, y: 80 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.5 }}
                className={s.info}>
                <motion.h2
                    initial={{ opacity: 0, x: -80 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                >{program.title}</motion.h2>
                <motion.p
                    initial={{ opacity: 0, x: -80 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.8 }}
                >{program.description}</motion.p>
                <ShowFeedback>
                    Записаться на курс
                </ShowFeedback>
            </motion.div>

            <Swiper
                spaceBetween={30}
                effect={'fade'}
                autoplay={{
                    delay: 1500
                }}
                modules={[EffectFade, Autoplay]}
                className={`program_swiper`}
            >
                {
                    program.modules.length > 0 &&
                    program.modules.map(el => (
                        <SwiperSlide key={el.id}>
                            <img src={backUrl + el.program_slide_image} alt='module' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </motion.article>
    );
};

export default TradeInfoMain;