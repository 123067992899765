import React, { FC } from 'react';
import s from './InfoColumn.module.scss'
import checkMark from '../images/checkMark.svg'
import checkMarkBlue from '../images/checkMarkBlue.svg'
import checkMarkOrange from '../images/checkMarkorange.svg'
import { IProgram } from '../../../redux/types';

interface InfoColumnProps {
    modules: IProgram['modules']
    title: IProgram['bottom_title']
}

const InfoColumn: FC<InfoColumnProps> = ({ modules, title }) => {

    return (
        <div className={s.info_block}>
            <h2 className={s.title}>
                {title}
            </h2>

            <div className={s.modules}>
                {
                    modules.length > 0 &&
                    modules.map((el, i) => (
                        <div
                            data-module={i + 1}
                            key={el.id} className={s.module}>
                            <div className={s.module_num}>
                                <p>{el.card_name}</p>
                            </div>

                            <h2>{el.card_title}</h2>

                            <ul className={s.list}>
                                <li className={s.list_item}>
                                    <img
                                        src={
                                            i === 0 ? checkMark
                                                :
                                                i === 1 ? checkMarkBlue
                                                    : checkMarkOrange
                                        }
                                        alt="checkMark" />
                                    <p>{el.card_item_1}</p>
                                </li>
                                <li className={s.list_item}>
                                    <img
                                        src={
                                            i === 0 ? checkMark
                                                :
                                                i === 1 ? checkMarkBlue
                                                    : checkMarkOrange
                                        }
                                        alt="checkMark" />
                                    <p>{el.card_item_2}</p>
                                </li>
                                <li className={s.list_item}>
                                    <img
                                        src={
                                            i === 0 ? checkMark
                                                :
                                                i === 1 ? checkMarkBlue
                                                    : checkMarkOrange
                                        }
                                        alt="checkMark" />
                                    <p>{el.card_item_3}</p>
                                </li>
                                <li className={s.list_item}>
                                    <img
                                        src={
                                            i === 0 ? checkMark
                                                :
                                                i === 1 ? checkMarkBlue
                                                    : checkMarkOrange
                                        }
                                        alt="checkMark" />
                                    <p>{el.card_item_4}</p>
                                </li>
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default InfoColumn;