import React, { FC, useState } from 'react';
import s from './Hero.module.scss';
import { motion } from "framer-motion";
import HeroSwiper from './HeroSwiper/HeroSwiper';

const Hero: FC = () => {
    const [mousePosition, setMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const x = (e.clientX / window.innerWidth - 0.5) * 20;
        const y = (e.clientY / window.innerHeight - 0.5) * -20;
        setMousePosition({ x, y });
    };

    return (
        <article className={s.wrapper}>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <motion.div
                    className={s.mouse_move}
                    onMouseMove={handleMouseMove}
                    style={{
                        // width: '95%',
                        // height: '700px',
                        perspective: 1000,
                        // margin: "40px 0",
                    }}
                >
                    <motion.div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontSize: "24px",
                            borderRadius: 20,
                            overflow: 'hidden',
                        }}
                    >
                        <HeroSwiper mousePosition={mousePosition} />
                    </motion.div>
                </motion.div>
            </motion.div>
        </article>
    );
};

export default Hero;