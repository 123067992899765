import React, { FC } from 'react';
import s from '../ModuleCard.module.scss'

interface RatingProps {
    label: string;
    value: number;
    max: number;
}

const Rating: FC<RatingProps> = ({ label, max, value }) => {
    return (
        <div className={s.rating_item}>
            <span className={s.label}>{label}</span>
            <div className={s.dots}>
                {Array.from({ length: max }, (_, i) => (
                    <span key={i} className={`${s.dot} ${i < value ? `${s.active}` : ''}`}></span>
                ))}
            </div>
        </div>
    );
};

export default Rating;